import './main.scss'

class Loader {
  num = 0
  isLoaded = 0
}

document.addEventListener('DOMContentLoaded', () => {
  const loadedData = new Loader()
  const img1 = new Image()
  img1.onload = () => onImageLoaded(loadedData)
  img1.src = './assets/inkbound-logo.webp'

  const img2 = new Image()
  img2.onload = () => onImageLoaded(loadedData)
  img2.src = './assets/web-offer.webp'

  const img3 = new Image()
  img3.onload = () => onImageLoaded(loadedData)
  img3.src = './assets/byline-bg.webp'

  const img4 = new Image()
  img4.onload = () => onImageLoaded(loadedData)
  img4.src = './assets/byline-bg2.webp'

  function onImageLoaded(loadedData:Loader) {
    loadedData.num++

    if (loadedData.num >= 4 && loadedData.isLoaded === 0)  {
      loadedData.isLoaded = 1
      
      const blocker = document.getElementById('blocker') as HTMLElement
      const header = document.getElementById('header') as HTMLElement
      const byline = document.getElementById('byline') as HTMLElement
      const borderHolderBg = document.getElementById('bg') as HTMLElement
      const offer = document.getElementById('offer') as HTMLElement
      const signup = document.getElementById('signup') as HTMLElement
      const trailer = document.getElementById('trailer') as HTMLElement
      const steam = document.getElementById('steam') as HTMLElement
      const social = document.getElementById('social') as HTMLElement
      const footer = document.getElementById('footer') as HTMLElement

      const timeStart = 30 // wait 30ms
      setTimeout(() => setVisible(blocker), timeStart)
      setTimeout(() => setVisible(header), timeStart + 100)
      setTimeout(() => setVisible(byline), timeStart + 300)
      setTimeout(() => setVisible(borderHolderBg), timeStart + 300)
      setTimeout(() => setVisible(offer), timeStart + 400)
      setTimeout(() => setVisible(signup), timeStart + 400)
      setTimeout(() => setVisible(trailer), timeStart + 500)
      setTimeout(() => setVisible(steam), timeStart + 600)
      setTimeout(() => setVisible(social), timeStart + 700)
      setTimeout(() => setVisible(footer), timeStart + 800)
    }
  }

  function setVisible(obj:HTMLElement) {
    obj.classList.add("visible")
  }
})
